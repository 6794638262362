import config from "../config.json";

export const baseUrl = config.baseUrl;

//ecommerce
export const loadProducts = baseUrl + "/products/";
export const getProduct = baseUrl + "/products/getProduct";

//delivery
export const reviewAndPay = baseUrl + "/create-checkout-session";
