//Config
export const SET_IS_LOADING = "SET_IS_LOADING";
export const TOGGLE_SLIDE_MENU_OPEN = "TOGGLE_SLIDE_MENU_OPEN";
export const TOGGLE_OPTIONS_MENU = "TOGGLE_OPTIONS_MENU";
export const SET_DIMENSIONS = "SET_DIMENSIONS";
export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const TOGGLE_SEARCH_MOBILE = "TOGGLE_SEARCH_MOBILE";

//App
export const TOGGLE_FILTER = "TOGGLE_FILTER";

//Api
export const SET_USER = "SET_USER";
export const SET_FORM_VALIDATION_LOGIN = "SET_FORM_VALIDATION_LOGIN";
export const SET_ERROR_GENERAL = "SET_ERROR_GENERAL";

//Ecommerce
export const SET_SEARCH = "SET_SEARCH";
export const SET_FILTERS = "SET_FILTERS";
export const SET_FILTERS_STRUCTURES = "SET_FILTERS_STRUCTURES";
export const SET_IS_LOADING_SHOP = "SET_IS_LOADING_SHOP";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PAGE = "SET_PAGE";
export const SET_SORT = "SET_SORT";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const RESET_FILTER = "RESET_FILTER";
export const SET_CURRENT_FILTER = "SET_CURRENT_FILTER";

//ViewProduct
export const SET_IS_LOADING_VIEW = "SET_IS_LOADING_VIEW";
export const SET_IS_LOADING_IMAGES = "SET_IS_LOADING_IMAGES";
export const SET_PRODUCT_SELECTED = "SET_PRODUCT_SELECTED";
export const CLEAN_PRODUCT_SELECTED = "CLEAN_PRODUCT_SELECTED";
export const ADD_PICTURE = "ADD_PICTURE";
export const SET_COLORS = "SET_COLORS";
export const SET_COLOR = "SET_COLOR";
export const SET_SIZE = "SET_SIZE";
export const SET_QTY = "SET_QTY";
export const SHOW_ERRORS = "SHOW_ERRORS";
export const SET_RECOMENDATIONS = "SET_RECOMENDATIONS";

//Bag
export const SET_ITEMS = "SET_ITEMS";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";

//Delivery
export const INIT_DELIVERY = "INIT_DELIVERY";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_PERSONAL = "SET_PERSONAL";
export const SET_ERRORS_DELIVERY = "SET_ERRORS_DELIVERY";
export const SET_ERRORS_HELPERTEXT = "SET_ERRORS_HELPERTEXT";
export const SET_URL_PAYMENT = "SET_URL_PAYMENT";

//Wishlist
export const SET_ITEMS_WISHLIST = "SET_ITEMS_WISHLIST";
export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const CLEAN_ITEMS = "CLEAN_ITEMS";
